<template>
	<AuthWrapper>
		<div class="auth-contents">
			<a-form name="forgotPassword" layout="vertical">
				<sdHeading as="h3">Forgot Password?</sdHeading>
				<p class="forgot-text">
					Enter the email address you used when you joined and we’ll send you
					instructions to reset your password.
				</p>
				<a-form-item name="email" label="Email Address *">
					<a-input
						type="text"
						name="email"
						v-model:value="formData.email"
						placeholder="name@example.com"
					/>
					<span class="error-messages" v-if="v$.formData.email.$error">
						{{ v$.formData.email.$errors[0].$message }}
					</span>
				</a-form-item>
				<a-form-item>
					<sdButton
						class="btn-reset"
						htmlType="submit"
						type="primary"
						size="large"
						@click="generateForgotPasswordToken"
					>
						{{
							store.state.actions.isLoading ? "Loading..." : "Send Reset Instructions"
						}}
					</sdButton>
				</a-form-item>
				<p class="return-text">
					Return to <router-link to="/login">Sign In</router-link>
				</p>
			</a-form>
		</div>
	</AuthWrapper>
</template>
<script>
import { AuthWrapper } from "./style";

import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

import UserService from "@/services/UserService";
import store from "@/vuex/store";

export default {
	name: "ForgotPassword",

	components: { AuthWrapper },

	data() {
		return {
			v$: useVuelidate(),
			isLoading: false,
			formData: {
				email: ""
			},
			store: store
		};
	},

	methods: {
		async generateForgotPasswordToken() {
			this.v$.formData.$touch();
			this.$store.dispatch("actions/setLoading", true);
			if (this.v$.formData.$pending || this.v$.formData.$error) {
				this.$store.dispatch("actions/setLoading", false);
				return;
			}

			await UserService.generateForgotPasswordToken(this.formData.email);

			this.$store.dispatch("actions/setLoading", false);

			window.showAlert(
				"success",
				"Success!",
				"If the email exists, you will receive all the instructions on it."
			);

			this.$router.push("/login");
		}
	},

	validations() {
		return {
			formData: {
				email: {
					required: helpers.withMessage("The email field is required", required),

					email: helpers.withMessage("The field email is not valid", email)
				}
			}
		};
	}
};
</script>
